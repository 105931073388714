import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function WhyGeorgia() {
  return (
    <Layout>
      <Helmet title="Why Georgia | John Mayer" />

      <h1>Why Georgia</h1>

      <h2>John Mayer</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>Gsus2</Ch>I am driving<Ch>D4</Ch> up
          </p>
          <p>
            <Ch>G</Ch>85 in<Ch>D4</Ch> the
          </p>
          <p>
            <Ch>Gsus2</Ch>Kind of <Ch>D4</Ch>morning that
          </p>
          <p>
            <Ch>Gsus2</Ch>Lasts all <Ch>D4</Ch>after<Ch>C6/9</Ch>noon
          </p>
          <p>I'm just stuck inside the gloom</p>
          <p>
            <b>Gsus2 D4</b>
          </p>
          <p>
            <Ch>Gsus2</Ch>Four more <Ch>D4</Ch>exits to
          </p>
          <p>
            <Ch>Gsus2</Ch>My apart<Ch>D4</Ch>ment but
          </p>
          <p>
            <Ch>Gsus2</Ch>I am tempt<Ch>D4</Ch>ed to
          </p>
          <p>
            <Ch>Gsus2</Ch>Keep the <Ch>D4</Ch>car in <Ch>C6/9</Ch>drive
          </p>
          <p>And leave it all behind</p>
          <p>
            <b>Gsus2 D4</b>
          </p>
        </Verse>
        <PreChorus>
          <p>
            'Cause <Ch>Em</Ch>I won<Ch>D/F#</Ch>der some<Ch>G</Ch>times
          </p>
          <p>
            About the <Ch>C</Ch>outcome
          </p>
          <p>
            Of a <Ch>Em</Ch>still <Ch>D/F#</Ch>verdictless<Ch>G</Ch> life
            <Ch>A7sus4</Ch>
          </p>
        </PreChorus>
        <Chorus>
          <p>
            Am I <Ch>D</Ch>living it<Ch>A</Ch> right<Ch>G</Ch>?
          </p>
          <p>
            Am I <Ch>D</Ch>living it<Ch>A</Ch> right<Ch>Em7</Ch>?
          </p>
          <p>
            Am I <Ch>D</Ch>living it<Ch>A</Ch> right<Ch>G</Ch>?
          </p>
          <p>
            <Ch>Fsus2</Ch>Why, why <Ch>C</Ch>Georgia why?
          </p>
          <p>
            <b>Gsus2 D4</b>
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>Gsus2</Ch>I rent a <Ch>D4</Ch>room and I
          </p>
          <p>
            <Ch>Gsus2</Ch>Fill the <Ch>D4</Ch>spaces with
          </p>
          <p>
            <Ch>Gsus2</Ch>Wooden <Ch>D4</Ch>places to
          </p>
          <p>
            <Ch>Gsus2</Ch>Make it <Ch>D4</Ch>feel like <Ch>C6/9</Ch>home
          </p>
          <p>But all I feel's alone</p>
          <p>
            <b>Gsus2 D4</b>
          </p>
          <p>
            <Ch>Gsus2</Ch>It might be a <Ch>D4</Ch>quarter life <Ch>C6/9</Ch>
            crisis
          </p>
          <p>Or just a stirring in my soul</p>
          <p>
            <b>Gsus2 D4</b>
          </p>
          <p>Either way</p>
        </Verse>
        <PreChorus>
          <p>
            <Ch>Em</Ch>I won<Ch>D/F#</Ch>der some<Ch>G</Ch>times
          </p>
          <p>
            About the <Ch>C</Ch>outcome
          </p>
          <p>
            Of a <Ch>Em</Ch>still <Ch>D/F#</Ch>verdictless<Ch>G</Ch> life
            <Ch>A7sus4</Ch>
          </p>
        </PreChorus>
        <Chorus>
          <p>
            Am I <Ch>D</Ch>living it<Ch>A</Ch> right<Ch>G</Ch>?
          </p>
          <p>
            Am I <Ch>D</Ch>living it<Ch>A</Ch> right<Ch>Em7</Ch>?
          </p>
          <p>
            Am I <Ch>D</Ch>living it<Ch>A</Ch> right<Ch>G</Ch>?
          </p>
          <p>
            <Ch>Fsus2</Ch>Why, why <Ch>C</Ch>Georgia why?
          </p>
          <p>
            <b>G C G</b>
          </p>
        </Chorus>
        <Bridge>
          <p>
            <Ch>F</Ch>So what<Ch>B</Ch> so <Ch>C</Ch>I've got a <Ch>B</Ch>smile
            on
          </p>
          <p>
            <Ch>F</Ch>But it's <Ch>B</Ch>hiding the <Ch>C</Ch>quiet super
            <Ch>B</Ch>stitions in my <Ch>G</Ch>head
          </p>
          <p>
            Don't <Ch>C</Ch>beli<Ch>D</Ch>eve <Ch>C</Ch>me
          </p>
          <p>
            <Ch>G</Ch>Don't <Ch>C</Ch>be<Ch>D</Ch>lieve <Ch>C</Ch>me
          </p>
          <p>
            When<Ch>Em7</Ch> I say <Ch>D</Ch>I've <Ch>Gm7</Ch>got it<Ch>Am7</Ch>
            down
          </p>
          <p>
            <b>Gsus2 D4</b>
          </p>
        </Bridge>
        <Verse>
          <p>
            <Ch>Gsus2</Ch>Everybody<Ch>D4</Ch> is
          </p>
          <p>
            <Ch>Gsus2</Ch>Just a stranger<Ch>D4</Ch> but
          </p>
          <p>
            <Ch>Gsus2</Ch>That's the <Ch>D4</Ch>danger in
          </p>
          <p>
            <Ch>Gsus2</Ch>Going <Ch>D4</Ch>my own <Ch>C6/9</Ch>way
          </p>
          <p>I guess it's a price I have to pay</p>
          <p>
            <b>Gsus2 D4</b>
          </p>
        </Verse>
        <PreChorus>
          <p>Still</p>
          <p>
            <Ch>Em</Ch>"Everything <Ch>D/F#</Ch>happens
          </p>
          <p>
            For a <Ch>G</Ch>reason"
          </p>
          <p>
            Is no <Ch>A7sus4</Ch>reason not to ask myself
          </p>
        </PreChorus>
        <Chorus>
          <p>
            Am I <Ch>D</Ch>living it<Ch>A</Ch> right<Ch>G</Ch>?
          </p>
          <p>
            Am I <Ch>D</Ch>living it<Ch>A</Ch> right<Ch>Em7</Ch>?
          </p>
          <p>
            Am I <Ch>D</Ch>living it<Ch>A</Ch> right<Ch>G</Ch>?
          </p>
          <p>
            <Ch>Fsus2</Ch>Why, why <Ch>C</Ch>Georgia why?
          </p>
          <p>
            <b>G C G</b>
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
